<template>
  <div class="container">
    <a id="sponsor" href="https://embl.org" rel="noopener">
      <!-- <img id="primary" src="./img/embo-primary-black.svg" alt="EMBO" title="EMBO"> -->
      <img v-if="displayMode === 'lightMode'" src="./Sponsors/img/embo-primary-black.svg" alt="EMBO" title="EMBO" />
      <img v-else src="./Sponsors/img/embo-primary-white.svg" alt="EMBO" title="EMBO" />
    </a>
    <p>
      The EMBO Workshop on 'Visualizing Biological Data' (VIZBI 2023) took place March 28&ndash;31 at EMBL in
      Heidelberg, Germany. This was the 13th international meeting on this topic. While primarily an in-person event,
      there was also an option for online participation.
    </p>
    <HeroImage
      heroImage="Hero.jpg"
      flyerLowRes="Poster.jpg"
      flyerHiRes="Poster.pdf"
      assets="Assets.zip"
      credit="EMBL 2023 | Aleksandra Krolik" />
    <p>
      VIZBI 2023 featured talks from 21 world-leading researchers showcasing visualizations transforming how life
      scientists view data, and driving key advances in molecular biology, systems biology, biomedical science, and
      ecology.
    </p>
    <p style="hyphens: auto !important">
      There were also two satellite events: (1) a pre-workshop
      <router-link to="/2023/Masterclass">Masterclass</router-link> provides opportunities for data visualization
      training from leading experts, and (2) a post-workshop evening
      <router-link to="/2023/Showcase">Showcase</router-link> event highlights life science communication in film.
    </p>
    <p>
      The VIZBI meetings brought together a diverse community, including bioinformaticians, data scientists, computer
      scientists, and experimentalists, as well as medical illustrators, graphic designers, and graphic artists.
    </p>
    <!--<p>All participants will have the opportunity to present a <a href="Posters/">poster and lightning talk</a> describing their work.</p> -->
    <p>
      All participants had the opportunity to present a <router-link to="/2023/Posters">poster</router-link> and
      <router-link :to="{name: 'Posters', hash: '#Lightning'}">lightning talk</router-link> describing their work. To
      see examples from previous VIZBIs, please take a look at the
      <router-link to="/Posters">VIZBI poster collection</router-link>.
    </p>
    <p>
      VIZBI 2023 was an exciting event that helped raise the standard of data visualization in the life sciences.
      Highlights from the meeting are available on the VIZBI <router-link to="/blog">blog</router-link>,
      <router-link to="/posters/2023">posters</router-link>, <router-link to="/photos/2023">photos</router-link>, and
      <router-link to="/videos">videos</router-link> pages as well as on our
      <a href="https://twitter.com/vizbi">Twitter timeline</a>.
    </p>
    <p>
      To receive updates about VIZBI, we encourage you to join the
      <router-link to="/Mail">VIZBI mailing list</router-link>.
    </p>
    <!-- <a href="http://www.iscb.org/" rel="nofollow"><img src="Sponsors/img/ISCB.jpg" alt="ISCB" title="ISCB" align="absmiddle" /></a> -->
    <KeyDates :items="keyDateItems" />
  </div>
</template>

<script setup>
import {inject} from "vue";
const displayMode = inject("displayMode");
import HeroImage from "@/components/HeroImage.vue";
import KeyDates from "@/components/KeyDates.vue";
const keyDateItems = {
  "financial assistance": {
    daysBefore: -65,
    link: "Registration#waivers"
  },
  "on-site registration": {
    daysBefore: -32,
    link: "Registration"
  },
  "poster upload": {
    daysBefore: -18,
    link: "Posters#submissions"
  },
  "Art & Biology upload": {
    daysBefore: -18,
    link: "Posters#art"
  },
  "online registration": {
    daysBefore: -7,
    link: "Registration"
  },
  "Masterclass registration": {
    daysBefore: -1,
    link: "Registration"
  },
  "VIZBI Masterclass": {
    dates: "Mar 27-28",
    link: "Masterclass"
  },
  "VIZBI Workshop": {
    dates: "Mar 28-31",
    link: "Program"
  }
};
function tense(present, past) {
  const event_has_past = false;
  if (event_has_past) {
    return past;
  } else {
    return present;
  }
}
</script>

<style scoped>
/* Container holding the image and the text */
.container {
  position: relative;
}
#sponsor img {
  display: none;
  /* May need some adjustments depending on aspect ratio etc */
  /* https://stackoverflow.com/q/3029422 */
  vertical-align: middle;
  float: right;
  position: absolute;
  /*position: relative; /* allows for location changes */
  top: -55px;
  right: 8px;
  height: 45px;
  margin-top: 5px;
  margin-left: 0px;
  padding: 0px;
}
.medium #sponsor img {
  display: block;
  top: -50px;
  height: 35px;
}
.narrow #sponsor img {
  display: block;
  top: -47px;
  height: 30px;
}
.narrow table {
  /* width: 100%; Better? */
  font-size: 13px;
}
b {
  font-weight: 600;
}
span {
  font-weight: 200;
}
td {
  vertical-align: top;
}
</style>
