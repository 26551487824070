<template>
  <p>
    The VIZBI Masterclass is an intensive training event covering state-of-the-art methods in data visualization, taught
    by leading experts and practitioners. The Masterclass takes place March 12 (<a
      :href="`${timeanddate}?day=12&month=3&year=2024&${timezones}`"
      >9:00am - 6:00pm
    </a>
    Pacific Daylight Time) at the University of Southern California in Los Angeles, and will also be streamed online.
    <!-- <router-link to="/2023/Registration"><span class="buttonish">Register&nbsp;now</span></router-link> -->
  </p>
  <!--<router-link to="/2023/Registration" title="Click to register">-->
  <HeroImage
    heroImage="Masterclass.jpeg"
    credit="Hana Pokojna, Christian Stolte, Seán O'Donoghue, Erez Lieberman,
    Juri Rappsilber, Alex Ritter, Luis Gutierrez-Heredia, Emmanuel Reynaud" />
  <!--</router-link>-->
  <RegisterNowWrapper />
  <h4>Who is the Masterclass for?</h4>
  <p>
    We have designed the Masterclass primarily to help life scientists. However, it will also be useful for anyone
    working with large, complex datasets &mdash; this includes scientists working in other fields, as well as
    programmers, designers, and communicators.
  </p>

  <h4>Why should I attend?</h4>
  <p>
    Data visualization is a powerful tool that can help you find insights buried in large, complex data, and to
    communicate more effectively. Unfortunately, there are currently few opportunities to get DataVis training. By
    attending the VIZBI Masterclass, you can learn directly from leading practitioners who have developed cutting-edge
    DataVis methods.
  </p>

  <!--
  <h4>What will I learn?</h4>
  <p>This course provides you the opportunity to:</p>
  <ul>
    <li>
      Learn key principles of human visual perception, and get an overview of current DataVis techniques and tools;
    </li>
    <li>
      Learn general strategies for creating insightful visual representations from large, complex, multidimensional
      datasets;
    </li>
    <li>Learn how to create clear and compelling visualizations for communication;</li>
    <li>Learn how to choose design elements and attributes, with an emphasis on typography;</li>
    <li>Learn how to create powerful, interactive visualizations using cutting-edge GPU programming methods;</li>
    <li>Learn about newly emerging strategies for visualizing time-oriented data;</li>
    <li>Connect with leading data visualization experts and practitioners.</li>
  </ul>
-->

  <h4>What do I need to know to participate?</h4>
  <p>
    The VIZBI Masterclass has been designed to be accessible by anyone working with data on a regular basis.
    <!--Some
    limited programming experience is recommended for the session on GPU-driven interactive visualization.-->
  </p>
  <h4 id="both"><a name="both" />I'm already attending the VIZBI Workshop - why should I go to the Masterclass?</h4>
  <p style="display: inline">
    The Masterclass is a satellite event to the <router-link to="/2023">VIZBI Workshop</router-link> (March 13–15). The
    Masterclass and Workshop are quite different, with little overlap in material. The Masterclass focuses on training
    in general methods, while the Workshop focuses on visualizations that address specific biological research
    challenges. In the Masterclass, the two morning sessions cover some principles that may be assumed knowledge for
    some of the Workshop talks. The afternoon sessions focuses on DataVis topics that will not be specifically addressed
    during the Workshop. So, if you have the time, money, and motivation, it can certainly make sense to attend both
    events. At recent VIZBI meetings, many participants and speakers attended both events.
  </p>
  <h4>Further questions?</h4>
  <p>If you have further questions about the VIZBI Masterclass, please write to <Email to="masterclass" />.</p>
  <p />
  <!--<p><ButtonBespoke :outstanding="true" @click="$router.push('/2023/Registration')">Register now</ButtonBespoke></p>-->
</template>

<script setup>
//import ButtonBespoke from "@/components/ButtonBespoke";
import Email from "@/components/Email";
import HeroImage from "@/components/HeroImage.vue";
import RegisterNowWrapper from "@/components/CallToAction/RegisterNowWrapper";

const timeanddate = "https://www.timeanddate.com/worldclock/meetingtime.html";
const timezones = "p1=37&p2=136&p3=179&p4=224";
</script>

<style scoped>
table td {
  vertical-align: top;
  margin-top: 29px;
  margin-bottom: 29px;
}
span.buttonish {
  color: white;
  background-color: var(--brand-color);
  padding: 2px 4px;
  border-radius: 4px;
}
img {
  width: 100%;
}
p button {
  margin-top: 16px;
  margin-bottom: 16px;
}
li {
  margin-bottom: 0.3rem;
}
</style>
