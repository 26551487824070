<template>
  <div class="container">
    <p>
      Due to the COVID-19 pandemic, we postponed the conference on 'Visualizing Biological Data' (VIZBI 2020),
      originally scheduled for May 27-30 at USC in Los Angeles.
    </p>
    <HeroImage heroImage="Hero.jpg" credit="Beata Mierzwa" />
    <p>
      The conference was rescheduled to take place 22 months later, on March 16-18, 2022, at the same venue in Los
      Angeles.
    </p>

    <p>The satellite workshop on 'Visual Storytelling in Biology' was rescheduled for May, 2021, still at USC.</p>

    <p>
      To receive updates about VIZBI, we encourage you to join the
      <router-link to="/Mail">VIZBI mailing list</router-link>.
    </p>
    <KeyDates :items="keyDateItems" />
  </div>
</template>

<script setup>
import HeroImage from "@/components/HeroImage.vue";
import KeyDates from "@/components/KeyDates.vue";
const keyDateItems = {
  "on-site registration": {
    daysBefore: -32,
    link: "Registration"
  },
  "poster upload": {
    daysBefore: -18,
    link: "Posters#submissions"
  },
  "Art & Biology upload": {
    daysBefore: -18,
    link: "Posters#art"
  },
  "online registration": {
    daysBefore: -7,
    link: "Registration"
  },
  "Masterclass registration": {
    daysBefore: -1,
    link: "Registration"
  },
  "VIZBI Masterclass": {
    dates: "Mar 12",
    link: "Masterclass"
  },
  "VIZBI Workshop": {
    dates: "Mar 13-15",
    link: "Program"
  }
};

function tense(present, past) {
  const event_has_past = false;
  if (event_has_past) {
    return past;
  } else {
    return present;
  }
}
</script>
