<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <!-- needs to be here, not in DialogModel - otherwise 'not connected' bug -->
  <img v-if="fullScreenCapable" id="fullScreen" src="@/assets/img/full-screen.svg" @click="goFullscreen" />
  <DialogModal v-if="image && image.url" :fixed-width="false" :frameless="true" @hide="exitFullscreen">
    <figure ref="highlightedFigure">
      <!-- https://imkev.dev/fetchpriority-opportunity -->
      <img :src="image.url" fetchpriority="high" />
      <!-- @click.prevent="this.$emit('hide')" -->
      <caption @click.stop>
        <span v-if="image.id && image.owner && image.ownername">
          <a :href="`${flickr}/${image.owner}/${image.id}`">flickr</a> /
          <a :href="`${flickr}/${image.owner}`">{{ image.ownername }}</a>
        </span>
      </caption>
    </figure>
  </DialogModal>
</template>

<script setup>
import DialogModal from "@/components/DialogModal";
import stringify from "json-stringify-safe";

// eslint-disable-next-line
const props = defineProps(["image"]);

let flickr = "https://www.flickr.com/photos";
let fullScreenCapable = document.fullscreenEnabled || false;

// eslint-disable-next-line
const log = function (message) {
  if (process.env.NODE_ENV === "development") {
    //console.log(message); // uncomment to show logs from this component
  }
};

import {ref} from "vue";
const highlightedFigure = ref(null);
const goFullscreen = async function () {
  if (!document.fullscreenEnabled) return; // early exit if not supported
  // https://stackoverflow.com//72297307
  log(`highlightedFigure = ${stringify(highlightedFigure.value)}`);
  try {
    await highlightedFigure.value.requestFullscreen();
  } catch (error) {
    console.log(`Full-screen error: ${error.message} (${error.name})`);
    alert(`Full-screen error: ${error.message} (${error.name})`);
  }
};

// eslint-disable-next-line
const emit = defineEmits(["hide", "imageLoading"]);
const exitFullscreen = async function () {
  if (document.fullscreenEnabled && document.fullscreenElement) {
    log(`The page is currently using full screen`);
    document.exitFullscreen();
  }
  emit("hide");
};
</script>

<style scoped>
body,
figure {
  margin: 0px;
  padding: 0px;
}
figure {
  /* iPhone height issue fix: use dvh not vh */
  /* https://stackoverflow.com/q/74313025 */
  height: 100dvh;
  width: 100vw;
  display: flex;
  cursor: zoom-out;
}
figure img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
  /* normally it's the default value */
}
caption {
  color: white;
  position: absolute;
  z-index: 1000;
  bottom: 15px;
  /*left: 15px; */
  text-align: center;
  width: 100%;
  /* https://stackoverflow.com/q/15096695 */
}
caption span {
  /* https://stackoverflow.com/q/11184117 */
  background: rgba(0, 0, 0, 0.5);
  padding: 0.3rem 0.7rem;
  border-radius: 8px;
}
caption a {
  color: white;
  text-decoration: none;
}
caption a:hover {
  text-decoration: underline;
}
#fullScreen {
  position: fixed;
  z-index: 1000;
  top: 15px;
  left: 15px;
  cursor: pointer;
}
</style>
