<template>
  <a name="top" />
  <table style="text-align: left">
    <tr>
      <th>Registrant type</th>
      <th>Registration fee<!--Normal registration--></th>
      <!--<th>ISCB registration</th>-->
    </tr>
    <tr>
      <td>Industry:</td>
      <td>€240</td>
      <!--<td>€204</td>-->
    </tr>
    <tr>
      <td>Academic:</td>
      <td>€190</td>
      <!--<td>€162</td>-->
    </tr>
    <tr>
      <td>Student:</td>
      <td>€140</td>
      <!--<td>€119</td>-->
    </tr>
  </table>
  <p>Registration is on a first-come, first-served basis; places are limited and will be confirmed after payment is received. As a registrant, you will be able to follow the talks either via live stream or via video playback; you will also have access to online interactive sessions where you can engage with speakers and other participants.</p>
  <p>All registrants are stongly encouraged to present a <a href="/2021/Posters/">poster and lightning talk</a>; to do so, you must first register and pay the registration fee, then upload your poster and presentation before 15 March 2021.</p>
  <p>To register, please use the link below, which will first guide you to create an account with the EMBL Course and Conference Office. Through this link, you will also be able to apply for an <a href="#CPP">Corporate Partnership Programme Fellowship</a>, if you are eligible. Please also read the <a href="http://www.embl.de/training/events/info_participants/terms/index.html">terms and conditions</a> for EMBL meetings. Online registration closes <strong>1 March 2021</strong>.</p>
  <p class="outstanding" style="margin-left: 0em; margin-bottom: 2em;"><a class="fake_button" href="https://embl.ungerboeck.com/PROD/emc00/register.aspx?OrgCode=10&EvtID=6003&AppCode=REG&CC=120091426516">Click here to register</a></p>

  <h4>ISCB Discount</h4>
  <p>We encourage participants to consider joining the <a href="http://iscb.org/">International Society for Computational Biology</a>. Thanks to our sponsors, we are able to offer ISCB members a 15% registration discount in addition to <a href="http://www.iscb.org/iscb-membership">other membership benefits</a>.
  </p>
  <!-- <form id="ISCB" action="Scripts/iscb_mem_status_check.php" method="POST" style="margin-bottom: 12px;">
    <em>Email:</em>
    <input type="text" id="email" name="email" size="27">
    <input id="submit" type="button" value="Click to verify your ISCB membership" onclick="$('#ISCB').StickyForm('process');" />
  </form>-->
  <div>
    <div id="message" />
  </div>
  <h4 id="CPP">EMBL Corporate Partnership Programme Fellowships</h4>
  <!--EMBL provides a limited number of fellowships that provide fee waivers for eligible students and researchers. However, for VIZBI 2021, applications have now closed; successful candidates will be notified in early January.</p>-->
  <p><b>Registration Fee Waivers.</b> All academic and student registrants are invited to apply for a registration fee waiver, provided by the EMBL Advanced Training Centre Corporate Partnership Programme. The registration fee waiver covers the registration sum that you have paid to attend the meeting. Conference participants are not required to pre-pay the registration fee to be selected for a fee waiver for a virtual meeting. If you have already paid the registration fee and are awarded a fee waiver, it will be reimbursed after the meeting.</p>
  <p><b>Childcare Grants.</b> For participants with children, there is the possibility to apply for a childcare grant provided by the EMBL Advanced Training Centre Corporate Partnership Programme, to offset childcare costs incurred by participants or speakers when participating at a virtual event. Eligible costs include fees for a babysitter or childcare facility or travel costs for a care giver. Please note that priority will be given to early stage researchers. A maximum amount of 500 EUR can be awarded per participant selected for the virtual childcare grant. Costs will be reimbursed after the meeting only once a reimbursement form and original receipts have been received. In order to apply for this grant, you must be registered by the registration deadline (01 March 2021).</p>
  <h4>Fellowship and Grant Application</h4>
  <p><b>Registration Fee Waivers.</b> You may apply for financial assistance by 1 February 2021. To apply, you must first create an account with the EMBL registration system. You will receive a Fellowship Application Link in your registration confirmation email. To complete your application, you need to submit an abstract (max. 2,000 characters) describing the work and you will be asked to answer questions regarding your motivation for applying and how your attendance will make a difference to your career. You will be able to edit your abstract and application details until the closing of the fellowship submissions. Application for financial support will not affect the outcome of your registration application.</p>
  <p><b>Childcare Grants.</b> Participants registered by the registration deadline will be emailed information about the childcare grants. You should apply for the grant by the deadline specified in the email.</p>
  <h4>Fellowship and Grant Selection</h4>
  <p>The selection results will be announced approximately 3 - 4 weeks before the event start date. Selection results do not impact your admission to the meeting. Registration fee waiver selection is based on your current work or study location, your motivation for applying, the reasons for needing financial support and the impact this event will have on your career. Childcare grants are allocated based on career stage, with priority given to early stage researchers.</p>
</template>

<style scoped>
table {
  border-collapse: collapse;
  margin-bottom: 1em;
  width: 100%;
  display: table;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: gray;
}
tr:nth-child(even) {
  background: #EEE;
}
</style>
