<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <NavigationPhotos
    :total-photos="photos.total"
    :years="photos.years"
    :photo-data-by-year="photos.byYear"
    @setYear="setYear" />
  <aside>
    To share VIZBI photos, upload them to <a href="https://www.flickr.com/signin">Flickr</a> &amp; tag with &lsquo;<a
      href="https://www.flickr.com/search/?tags=vizbi&sort=date-taken&view_all=1"
      >vizbi</a
    >&rsquo;.
  </aside>
  <div v-if="!initialLoad" id="wrapper">
    <section v-for="yearToShow in yearsToShow" :key="yearToShow">
      <h4>VIZBI {{ yearToShow }}</h4>
      <div class="photosInOneYear">
        <Photo
          v-for="photo in photos.byYear[yearToShow]"
          :key="photo.id"
          :photo="photo"
          :year="yearToShow"
          @highlight="updateHighlightedPhoto" />
      </div>
    </section>
  </div>
  <!-- mount PhotoHighlight once all photo data is loaded from flickr -->
  <PhotoHighlightState :image="photos.byID[photos.highlight]" @hide="updateHighlightedPhoto" />
  <LoadingNextPage v-if="moreToLoad" @fetchNextPage="showNextYear" />
</template>

<script setup>
// https://medium.com/@magyarn/simple-photo-app-with-vue-js-axios-and-flickr-api-part-1-e6e84bfe2580
import NavigationPhotos from "@/components/Navigation/Photos";
import LoadingNextPage from "@/components/LoadingNextPage";
import Photo from "@/components/Photo";
import PhotoHighlightState from "@/components/PhotoHighlightState";

// set up the key data object
import {reactive} from "vue";
const photos = reactive({
  allLoaded: false,
  byID: {},
  byYear: {},
  highlight: "",
  pagesTotal: 0,
  years: [],
  yearsHidden: [],
  total: 0
});

// eslint-disable-next-line
const log = function (message) {
  if (process.env.NODE_ENV === "development") {
    //console.log(message); // uncomment to show logs from this component
  }
};

const test = function (photosByYear) {
  log(`Test: photos.byYear`);
  if (!photosByYear[2016]) return;
  log(`Test:found 2016 photo data`);
  photosByYear[2016].forEach((photo) => {
    if (photo.id === "25575527352") {
      log(`Test: found Fred Brooks photo in photos.byYear`);
    }
    if (photo.id === "26323809936") {
      log(`Test: found Minardo photo in photos.byYear`);
    }
  });
};

// Function to fetch a page of Flickr photos and update the key data object
import {fetchFlickrPage} from "@/composables/useFlickrSearch";
import {keepScrollPosition} from "@/composables/useKeepScrollPosition";
import {arrayMergeUnique} from "@/composables/useArrayMergeAsync";
import {objectMergeConcatArrays} from "@/composables/useObjectMergeAsync";
const addPageOfPhotos = async function (page) {
  log(`addPageOfPhotos(${page})`);
  let data = await fetchFlickrPage(page);
  if (!data) return;
  // keep current scroll position when images are added (on next tick)
  keepScrollPosition();
  // below is ugly; can be cleaned up by destructuring
  Object.assign(photos.byID, data.byID);
  photos.byYear = await objectMergeConcatArrays(photos.byYear, data.byYear);
  photos.years = await arrayMergeUnique(photos.years, data.years);
  log(`addPageOfPhotos: years = ${photos.years}`);
  photos.yearsHidden = await arrayMergeUnique(photos.yearsHidden, data.yearsHidden);
  log(`addPageOfPhotos: yearsHidden = ${photos.yearsHidden}`);
  photos.total = Object.keys(photos.byID).length;
  photos.pagesTotal = data.pagesTotal;
  test(photos.byYear);
  return photos.total;
};

// Run above function when component mounts, quickly showing first photo page
import {onBeforeMount} from "vue";
import {useRoute} from "vue-router";
const route = useRoute();
import {ref} from "vue";
let year = ref(route.params.photoYear || ""); // empty string = all years
const [initialLoad, yearsToShow] = [ref(true), ref([])];
let page = 1; // [next page to fetch, total pages]
onBeforeMount(async () => {
  if (!year.value) {
    log(`called with no year in the URL - so load latest year first`);
    // https://zellwk.com/blog/async-await-in-loops/
    // https://www.w3schools.com/js/js_loop_for.asp
    for (; photos.years.length < 2; page++) {
      await addPageOfPhotos(page);
      if (page > photos.pagesTotal) break; // now that pagesTotal is defined
    }
    log(`Got all photos from latest year: ${photos.years[0]}`);
    // Now, show all photos from the latest Year
    yearsToShow.value.push(photos.yearsHidden.shift());
    log(`yearsToShow = ${yearsToShow.value}`);
  } else {
    log(`called with ${year.value} in the URL - so load that year first`);
    for (; !photos.years.includes(year.value); page++) {
      let photosReturned = await addPageOfPhotos(page);
      if (!photosReturned) break; // exit for loop
      if (page > photos.pagesTotal) break; // now that pagesTotal is defined
      log(`page = ${page}`);
    }
    if (photos.years.includes(year.value)) {
      log(`Got all photos from specified year: ${year.value}`);
      // Now, show all photos from the specified Year
      yearsToShow.value.push(year.value);
    }
  }
  initialLoad.value = false;
  log(`initialLoad = ${initialLoad.value}`);
});

// A function to fetch the remaining flickr pages
const fetchAllImages = async function (pagesTotal) {
  if (!pagesTotal) return; // early exit
  log(`fetchAllImages(${pagesTotal})`);
  // https://zellwk.com/blog/async-await-in-loops/
  // https://www.w3schools.com/js/js_loop_for.asp
  for (let repeat = 1; repeat <= 6; repeat++) {
    // fetch and merge flickr data mutiple times to improve consistency
    for (page = 1; page <= pagesTotal; page++) {
      // skip first page on first repeat - already loaded
      if (repeat === 1 && page === 1) continue;
      log(`fetchAllImages: [repeat, page] = ${[repeat, page]}`);
      setTimeout(await addPageOfPhotos(page), 4000);
    }
  }
  log(`Got all photos from all years`);
  photos.allLoaded = true;
};

// Run above function after DOM updates; all photo data are then local
import {onUpdated} from "vue";
let requestSentToFlickr = false; // set to true once started to get all
import {nextTick} from "vue";
onUpdated(async function () {
  //log(`updated()`);
  if (yearsToShow.value.length === 0) return; // wait until year is done
  //log(`yearsToShow.value = ${yearsToShow.value}`);
  if (requestSentToFlickr) return; // only run once
  requestSentToFlickr = true;
  log(`requestSentToFlickr = ${requestSentToFlickr}`);
  nextTick(() =>
    setTimeout(async () => {
      await fetchAllImages(photos.pagesTotal);
    }, 1000)
  );
});

// This keeps track of whether more images need to be loaded
import {computed} from "vue";
const moreToLoad = computed(() => {
  log(`moreToLoad() re-computed`);
  log(`moreToLoad: yearsHidden = ${photos.yearsHidden}`);
  if (year.value) {
    log(`moreToLoad: show only year ${year.value}`);
    return false;
  } else if (photos.yearsHidden.length > 0) {
    log(`moreToLoad: years currently hidden: ${photos.yearsHidden.length}`);
    return true;
  } else {
    log(`moreToLoad: don't show other years`);
    return false;
  }
});

// when user scroll to bottom, this shows next set of images
const showNextYear = function () {
  if (!moreToLoad.value) return; // early exit to fix bug;
  log(`showNextYear()`);
  if (photos.yearsHidden.length > 0) {
    // reveal photos for the next year
    yearsToShow.value.push(photos.yearsHidden.shift());
  }
};

// when user sets a specfic year via the dropdown menu
const setYear = function (yearToSet) {
  log(`setYear(${yearToSet})`);
  log(`years = ${photos.years}`);
  log(`years destructured = ${[...photos.years]}`);
  year.value = yearToSet;
  if (year.value === "") {
    photos.yearsHidden = [...photos.years]; // reset
    log(`yearsHidden = ${photos.yearsHidden}`);
    yearsToShow.value = [];
    yearsToShow.value.push(photos.yearsHidden.shift()); // initially, show only latest year
  } else {
    yearsToShow.value = [yearToSet];
  }
};

// when user toggles highlighting for a photo
import stringify from "json-stringify-safe";
const updateHighlightedPhoto = function (photoToHighlight) {
  log(`updateHighlightedPhoto(${stringify(photoToHighlight)})`);
  if (!photoToHighlight) {
    log(`no 'photoToHighlight'`);
    photos.highlight = "";
  } else if (photoToHighlight.id) {
    photos.highlight = photoToHighlight.id;
  } else {
    console.error(`photoToHighlight exists but no ID`);
  }
};
</script>

<style scoped>
aside {
  font-weight: 200;
  font-size: 0.9rem;
  margin-top: 8px;
  margin-bottom: 0px;
}
.photosInOneYear {
  display: flex;
  flex-direction: row;
  /* align-items: stretch; */
  flex-wrap: wrap;
  gap: 5px 5px; /* row-gap column-gap */
  list-style: none;
  margin: 0; /* 0.5rem 0 */
  padding: 0;
}
</style>
